#gridContainer .ag-theme-alpine {
  width: 100%;
  height: 100%;
}

#gridContainer .ag-theme-alpine {
  font-family: 'Barlow', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
    '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}

#gridContainer
  .ag-theme-alpine
  .ag-ltr
  .ag-side-bar-left
  .ag-tool-panel-wrapper {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0;
}

/* Column Header */
#gridContainer .ag-theme-alpine .ag-header {
  background: #ffffff;
  border-bottom: 1px solid #d1dee6;
}

/* Row */
#gridContainer .ag-theme-alpine .ag-row {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  background: #ffffff;
  border-bottom: 1px solid #d1dee6;
}

/* Grid Cells */
#gridContainer .ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  font-weight: 600;
  font-size: 12px;
  color: #8da9bf;
}

#gridContainer .ag-theme-alpine .ag-ltr .ag-cell {
  display: flex;
  align-items: center;
}

#gridContainer .ag-theme-alpine .ag-ltr .ag-cell > img {
  padding-right: 8px;
}

/* Sidebar */
#gridContainer .ag-theme-alpine .ag-ltr .ag-side-bar-left {
  border-radius: 5px;
}

/* Filter Panel */
#gridContainer .ag-theme-alpine .ag-filter-toolpanel-header {
  font-weight: 600;
  color: #4a4a4a !important;
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-apply-panel {
  border-top: none;
}

#gridContainer .ag-theme-alpine .ag-filter-toolpanel-instance-filter {
  border-left: none;
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-toolpanel-group-container {
  background: #ffffff;
}

#gridContainer .ag-theme-alpine .ag-filter-apply-panel-button {
  line-height: 1.5;
  border: none;
  color: #4c94ec !important;
  font-size: 12px;
}

/* Columns Panel */
#gridContainer .ag-column-select-list {
  background: #ffffff;
}

#gridContainer .ag-column-select-column {
  color: #4a4a4a;
  font-weight: 600;
  font-size: 12px;
}

#gridContainer .ag-theme-alpine .ag-virtual-list-item {
  border-bottom: 1px solid #D1DEE6;
}

/* Filter Container */
#gridContainer .ag-theme-alpine .ag-react-container {
  width: 320px;
}

/* No Rows Overlay */
#gridContainer .ag-theme-alpine .ag-overlay .ag-overlay-panel .ag-overlay-wrapper .ag-react-container {
  width: 100%;
  height: 454px;
}

/* To overcome ag-grid table disapperance issue on click on filter in table */
.ag-popup {
  height: 0 !important;
  min-height: 0 !important;
}
