body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

.MuiDialog-paper.MuiDialog-paper.MuiPickersModal-dialogRoot{
  max-width: 325px !important;
}
.MuiDialog-paper.MuiDialog-paper.MuiPickersModal-dialogRoot .MuiPickersModal-dialog{
  margin: 0px;
}